export default (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9858 23.0746L16.4023 9.48956H7.57581L11.9858 23.0746Z" fill="#E24329" />
    <path
      d="M1.38973 9.48956L0.0448073 13.6168C-0.0768968 13.9916 0.0545353 14.4053 0.375766 14.6389L11.9859 23.0745L1.38973 9.48956Z"
      fill="#FCA326"
    />
    <path
      d="M1.38977 9.4896H7.57581L4.91351 1.30801C4.77725 0.889435 4.18343 0.889435 4.04227 1.30801L1.38977 9.4896Z"
      fill="#E24329"
    />
    <path
      d="M22.5884 9.48956L23.9285 13.6168C24.0502 13.9916 23.9188 14.4053 23.5975 14.6389L11.9858 23.0745L22.5884 9.48956Z"
      fill="#FCA326"
    />
    <path
      d="M22.5884 9.4896H16.4023L19.0597 1.30801C19.196 0.889435 19.7898 0.889435 19.931 1.30801L22.5884 9.4896Z"
      fill="#E24329"
    />
    <path d="M11.9858 23.0745L16.4023 9.48956H22.5884L11.9858 23.0745Z" fill="#FC6D26" />
    <path d="M11.9858 23.0745L1.38977 9.48956H7.57581L11.9858 23.0745Z" fill="#FC6D26" />
  </svg>
);
