import { ReactNode } from 'react';
import { AxiosError } from 'axios';

export type SimpleErrorResponse = {
  error?: string;
  errorMsg?: string;
  errorMessage?: string;
  message?: string;
};

export type ErrorWithCodeResponse = SimpleErrorResponse & {
  code?: string;
};

export type ErrorItem<TErrorValueKeys = string> = {
  error: TErrorValueKeys;
  value?: string;
  count?: number;
};

export type ComplexErrorResponse<TErrorFieldNames extends string | number | symbol, TErrorValueKeys = string> = {
  errors: { [key in TErrorFieldNames]?: ErrorItem<TErrorValueKeys>[] };
};

export type ErrorFieldNamesFor<TError> =
  TError extends ComplexErrorResponse<infer TErrorFieldNames, any> ? TErrorFieldNames : never;

export type ErrorValueKeysFor<TError> =
  TError extends ComplexErrorResponse<any, infer TErrorValueKeys> ? TErrorValueKeys : never;

export type FlattenErrorFieldNamesToFirstError<TErrorResponse> = {
  [key in ErrorFieldNamesFor<TErrorResponse>]?: ErrorItem<ErrorValueKeysFor<TErrorResponse>>;
};

export type FlattenErrorFieldNamesToErrorList<TErrorResponse> = {
  [key in ErrorFieldNamesFor<TErrorResponse>]?: ErrorItem<ErrorValueKeysFor<TErrorResponse>>[];
};

export type ErrorResponse<TErrorFieldNames extends string | number | symbol, TErrorValueKeys = string> =
  | SimpleErrorResponse
  | ComplexErrorResponse<TErrorFieldNames, TErrorValueKeys>;

export const isSimpleError = (
  error?: SimpleErrorResponse | ComplexErrorResponse<any, any>,
): error is SimpleErrorResponse => {
  const simpleError = error as SimpleErrorResponse;
  return Boolean(
    simpleError && (simpleError.error || simpleError.errorMsg || simpleError.errorMessage || simpleError.message),
  );
};

export const isComplexError = (
  error?: SimpleErrorResponse | ComplexErrorResponse<any, any>,
): error is ComplexErrorResponse<any, any> => {
  const complexError = error as ComplexErrorResponse<any, any>;
  return Boolean(complexError && complexError.errors);
};

export type ErrorHandler<TErrorResponse extends ErrorResponse<any, any>> = (
  error: AxiosError<TErrorResponse>,
  defaultMessage?: string,
) => ReactNode;
