import { Button, ButtonProps, forwardRef, Icon } from '@bitrise/bitkit';

import { SocialLoginProviders } from '@/constants';
import BitbucketIcon from './BitbucketIcon';
import GitHubIcon from './GitHubIcon';
import GitlabIcon from './GitlabIcon';

export type SocialButtonProps = ButtonProps & {
  provider: SocialLoginProviders;
  textBefore?: string;
  textAfter?: string;
};

const SocialLoginButton = forwardRef(
  ({ provider, textBefore, textAfter = '', ...props }: SocialButtonProps, ref): JSX.Element => {
    let icon: JSX.Element;
    switch (provider) {
      case 'GitHub':
        icon = <GitHubIcon />;
        break;
      case 'Bitbucket':
        icon = <BitbucketIcon />;
        break;
      case 'GitLab':
        icon = <GitlabIcon />;
        break;
      case 'Google':
        icon = <Icon name="GoogleColor" />;
        break;
      case 'SSO':
      default:
        icon = <Icon name="Key" />;
    }

    return (
      <Button leftIcon={icon} ref={ref} variant="secondary" minWidth="160px" {...props}>
        {textBefore ? `${textBefore} ${provider} ${textAfter}` : provider}
      </Button>
    );
  },
);

export default SocialLoginButton;
