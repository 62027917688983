export default (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0.758672 2.37457C0.647955 2.37315 0.538267 2.39597 0.43731 2.44145C0.336353 2.48692 0.246574 2.55394 0.174276 2.63781C0.101979 2.72168 0.048915 2.82035 0.0188112 2.92691C-0.0112927 3.03346 -0.0177071 3.14531 1.83629e-05 3.25461L3.2205 22.8051C3.26063 23.0444 3.38377 23.2618 3.5683 23.4193C3.75282 23.5768 3.98693 23.6642 4.22951 23.6662H19.6795C19.8613 23.6685 20.038 23.6055 20.1772 23.4885C20.3165 23.3715 20.4091 23.2084 20.4381 23.0289L23.6586 3.25841C23.6763 3.14911 23.6699 3.03725 23.6398 2.9307C23.6097 2.82414 23.5567 2.72547 23.4844 2.6416C23.4121 2.55774 23.3223 2.49072 23.2213 2.44524C23.1204 2.39976 23.0107 2.37694 22.9 2.37837L0.758672 2.37457ZM14.3196 16.5045H9.38835L8.05312 9.52868H15.5145L14.3196 16.5045Z"
        fill="#2684FF"
      />
      <path
        d="M22.6344 9.52869H15.5145L14.3196 16.5045H9.38834L3.56567 23.4158C3.75023 23.5754 3.98552 23.6642 4.2295 23.6662H19.6833C19.8651 23.6685 20.0417 23.6055 20.181 23.4885C20.3202 23.3715 20.4128 23.2084 20.4419 23.0289L22.6344 9.52869Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="24.2807"
        y1="11.4822"
        x2="12.514"
        y2="20.6657"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="neutral.100" />
      </clipPath>
    </defs>
  </svg>
);
