import { Notification, NotificationProps } from '@bitrise/bitkit';

import useNotification from '@/hooks/useNotification';

type NotificationSlotProps = Omit<NotificationProps, 'status' | 'icon'>;
const NotificationSlot = ({ onClose, ...rest }: NotificationSlotProps): JSX.Element | null => {
  const { notification, removeNotification } = useNotification();
  const handleClose = () => {
    removeNotification();
    onClose?.();
  };

  return notification?.message ? (
    <Notification status={notification.status} iconName={notification.iconName} onClose={handleClose} {...rest}>
      {notification.message}
    </Notification>
  ) : null;
};

export { NotificationSlotProps };
export default NotificationSlot;
