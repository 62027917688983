import 'regenerator-runtime/runtime';
import '../datadog';
import '../hotjar-init';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { Layout } from '../components/page/Layout';
import { camelCaseKeys } from './changeCase';

type ReactComponent = Parameters<typeof React.createElement>[0];
type ReactProps = Parameters<typeof React.createElement>[1];

declare global {
  interface Window {
    pageProps: ReactProps;
  }
}

export function renderPageComponent(Page: ReactComponent, camelizeKeys = true): void {
  const page = (
    <Layout>
      <Page {...(camelizeKeys ? camelCaseKeys(window.pageProps) : window.pageProps)} />
    </Layout>
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('react-mount')!);
  root.render(page);
}
