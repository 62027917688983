import { ValidationResult, ValidationResultSummary, Validator } from '@/models/Validation';

const MIN_LENGTH = 8;
const MIXED_LETTER_REGEX = /[a-z]+.*[A-Z]+|[A-Z]+.*[a-z]+/;
const NUMBER_REGEX = /[0-9]/;
const REPETITION_REGEX = /(.)\1{2,}/;

export type SimpleValidationKeys = 'TOO_SHORT' | 'MIXED_CASE' | 'MISSING_NUMBER' | 'REPETITION';

export type StandardValidationKeys = SimpleValidationKeys | 'CONTAINS_USERNAME_OR_EMAIL';

const StandardValidatorRules: Validator<StandardValidationKeys>[] = [
  {
    key: 'TOO_SHORT',
    description: `${MIN_LENGTH} characters minimum`,
    validate: (password: string) => password.length < MIN_LENGTH,
  },
  {
    key: 'MIXED_CASE',
    description: 'Mix of uppercase and lowercase letters',
    validate: (password: string) => !MIXED_LETTER_REGEX.test(password),
  },
  {
    key: 'MISSING_NUMBER',
    description: 'At least one number',
    validate: (password: string) => !NUMBER_REGEX.test(password),
  },
  {
    key: 'REPETITION',
    description: 'Must not contain the same character 3 times in a row (aaa)',
    validate: (password: string) => password.length < 4 || REPETITION_REGEX.test(password),
  },
  {
    key: 'CONTAINS_USERNAME_OR_EMAIL',
    description: 'Must not contain your email or username',
    validate: (password: string, email?: string, username?: string) =>
      (!!username && password.includes(username)) || (!!email && password.includes(email)),
  },
];

const ValidatorRulesWithoutEmailAndUsername: Validator<string>[] = StandardValidatorRules.slice(0, -1);

export const validatePasswordWithoutEmailAndUsername = (
  password?: string,
): ValidationResultSummary<SimpleValidationKeys> => {
  const results = ValidatorRulesWithoutEmailAndUsername.map((rule) => {
    const { key, description } = rule;
    const isInvalid = Boolean(!password) || rule.validate(password);
    return { key, description, isInvalid } as ValidationResult<SimpleValidationKeys>;
  });

  return {
    results,
    isValid: results.every((r) => !r.isInvalid),
  };
};

export const validatePassword = (
  password: string,
  email: string,
  username: string,
): ValidationResultSummary<StandardValidationKeys> => {
  const results = StandardValidatorRules.map((rule) => {
    const { key, description } = rule;
    const isInvalid = Boolean(!password) || rule.validate(password, email, username);
    return { key, description, isInvalid } as ValidationResult<StandardValidationKeys>;
  });

  return {
    results,
    isValid: results.every((r) => !r.isInvalid),
  };
};
