import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Input,
  InputProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useResponsive,
} from '@bitrise/bitkit';

import ValidationTooltip from '@/components/ValidationTooltip/ValidationTooltip';
import {
  SimpleValidationKeys,
  StandardValidationKeys,
  validatePasswordWithoutEmailAndUsername,
} from '@/utils/formValidators/passwordValidator';

import { ValidationResultSummary } from '@/models/Validation';

type PasswordInputAndIconProps = InputProps & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  showText?: string;
  hideText?: string;
};

const PasswordInputAndIcon = ({
  name,
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  showText = 'Show password',
  hideText = 'Hide password',
  ...rest
}: PasswordInputAndIconProps) => {
  const [password, setPassword] = useState(value);
  const [revealPassword, setRevealPassword] = useState(false);

  return (
    <Input
      type={revealPassword ? 'text' : 'password'}
      name={name}
      placeholder={placeholder}
      value={password}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      rightAddon={
        <IconButton
          aria-label={revealPassword ? hideText : showText}
          iconName={revealPassword ? 'HidePassword' : 'ShowPassword'}
          onClick={() => setRevealPassword(!revealPassword)}
          variant="tertiary"
          _active={{ background: 'transparent' }}
          _hover={{ background: 'transparent' }}
        />
      }
      rightAddonPlacement="inside"
      inputStyle={{
        '&::-ms-reveal': {
          display: 'none',
        },
      }}
      {...rest}
    />
  );
};

export type RevealablePasswordInputProps = InputProps & {
  onValidate?: (isValid: boolean) => void;
  showText?: string;
  hideText?: string;
};

export const RevealablePasswordInput = ({
  name = 'password',
  value = '',
  placeholder,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: RevealablePasswordInputProps): JSX.Element => (
  <PasswordInputAndIcon
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    {...rest}
  />
);

export type ValidatedRevealablePasswordInputProps = RevealablePasswordInputProps & {
  email?: string;
  username?: string;
  login?: string;
};

export const ValidatedRevealablePasswordInput = ({
  name = 'password',
  value = '',
  email,
  username,
  login,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onValidate,
  ...rest
}: ValidatedRevealablePasswordInputProps): JSX.Element => {
  const { isMobile } = useResponsive();
  const [password, setPassword] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const validationResult = useMemo(() => validatePasswordWithoutEmailAndUsername(`${password}`), [password]);

  useEffect(() => {
    if (onValidate) {
      onValidate(validationResult.isValid);
    }
  }, [onValidate, validationResult.isValid]);

  useEffect(() => {
    setShowTooltip(isMobile || hasFocus || (!!password && !validationResult.isValid));
  }, [isMobile, hasFocus, password, validationResult.isValid]);

  const inputAndIcon = (
    <PasswordInputAndIcon
      name={name}
      placeholder={placeholder}
      value={password}
      onChange={(e) => {
        setPassword(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      onFocus={(e) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(e);
        }
      }}
      {...rest}
    />
  );

  const passwordTooltip = <ValidationTooltip title="Password requirements" results={validationResult.results} />;

  return (
    <Box display="flex" flexDirection="column">
      {isMobile && (
        <>
          {inputAndIcon}
          {showTooltip && (
            <Box display="flex" flexDirection="column" gap="8" marginBottom="20px">
              {passwordTooltip}
            </Box>
          )}
        </>
      )}
      {!isMobile && (
        <Popover placement="right" autoFocus={false} isOpen={hasFocus}>
          <PopoverTrigger>
            <Box>{inputAndIcon}</Box>
          </PopoverTrigger>
          <PopoverContent padding={16}>{passwordTooltip}</PopoverContent>
        </Popover>
      )}
    </Box>
  );
};

export type RevealablePasswordInputWithValidationProps = ValidatedRevealablePasswordInputProps & {
  validationResults: ValidationResultSummary<SimpleValidationKeys | StandardValidationKeys>;
};

export const RevealablePasswordInputWithValidation = ({
  name = 'password',
  value = '',
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onValidate = () => {},
  validationResults,
  ...rest
}: RevealablePasswordInputWithValidationProps): JSX.Element => {
  const { isMobile } = useResponsive();
  const [password, setPassword] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (onValidate) {
      onValidate(validationResults.isValid);
    }
  }, [onValidate, validationResults.isValid]);

  useEffect(() => {
    setShowTooltip(isMobile || hasFocus || (!!password && !validationResults.isValid));
  }, [isMobile, hasFocus, password, validationResults.isValid]);

  const inputAndIcon = (
    <PasswordInputAndIcon
      name={name}
      placeholder={placeholder}
      value={password}
      onChange={(e) => {
        setPassword(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      onFocus={(e) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(e);
        }
      }}
      {...rest}
    />
  );

  const passwordTooltip = <ValidationTooltip title="Your password should have:" results={validationResults.results} />;

  return (
    <Box display="flex" flexDirection="column">
      {isMobile && (
        <>
          {inputAndIcon}
          {showTooltip && (
            <Box display="flex" flexDirection="column" gap="8" marginTop="24px">
              {passwordTooltip}
            </Box>
          )}
        </>
      )}
      {!isMobile && (
        <Popover placement="right" autoFocus={false} isOpen={hasFocus}>
          <PopoverTrigger>
            <Box>{inputAndIcon}</Box>
          </PopoverTrigger>
          <PopoverContent padding={16}>{passwordTooltip}</PopoverContent>
        </Popover>
      )}
    </Box>
  );
};
