import { Box } from '@bitrise/bitkit';

export type LoginCardProps = {
  children: React.ReactNode;
};

const LoginCard = ({ children }: LoginCardProps) => (
  <Box
    border="1px solid"
    borderColor="neutral.90"
    borderRadius="12"
    padding="24"
    margin="0 auto"
    width="368px"
    backgroundColor="neutral.100"
  >
    {children}
  </Box>
);

export default LoginCard;
