import { Box, Icon } from '@bitrise/bitkit';

export type BackButtonProps = {
  text: string;
  onBack: () => void;
};

const BackButton = ({ text, onBack, ...rest }: BackButtonProps): JSX.Element => (
  <Box as="button" color="purple.50" onClick={onBack} textAlign="left" {...rest}>
    <Icon name="ArrowLeft" />
    {text}
  </Box>
);

export default BackButton;
