import { Box, Link } from '@bitrise/bitkit';
import ENVIRONMENT from '@/environment';
import { BitkitLogoWithText } from '../BitkitLogoWithText';

export type StaticHeaderProps = {
  children: React.ReactNode;
};

const StaticHeader = ({ children }: StaticHeaderProps): JSX.Element => (
  <Box display="flex" alignItems="center" justifyContent="flex-end" marginX="48" marginY="24">
    <Link href={ENVIRONMENT.BITRISE_WEBSITE_LANDING_ROOT_URL} flexBasis="50%">
      <BitkitLogoWithText />
    </Link>
    {children}
  </Box>
);

export default StaticHeader;
