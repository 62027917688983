import { Box, BoxProps, Icon, IconProps, Text, TextProps } from '@bitrise/bitkit';

const defaultColor = 'purple.10';

export const PageTitleIcon = ({ name, textColor = defaultColor }: IconProps): JSX.Element => (
  <Icon name={name} width="50px" textColor={textColor} height="50px" />
);

export const PageTitleHeading = ({ children, ...rest }: TextProps): JSX.Element => (
  <Text size="6" fontWeight="bold" textColor={defaultColor} {...rest}>
    {children}
  </Text>
);

export const PageTitleSubheading = ({ children, ...rest }: TextProps): JSX.Element => (
  <Text textColor={defaultColor} {...rest}>
    {children}
  </Text>
);

export type PageTitleProps = BoxProps;

const PageTitle = ({ children, ...rest }: PageTitleProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap="8" marginBottom="32" {...rest}>
      {children}
    </Box>
  );
};

export default PageTitle;
