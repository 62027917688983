import { ReactNode } from 'react';
import { Box, Text, BoxProps } from '@bitrise/bitkit';

export type HorizontalRulerWithContentProps = BoxProps & {
  children: ReactNode;
};

const HorizontalRulerWithContent = ({ children, ...rest }: HorizontalRulerWithContentProps): JSX.Element => (
  <Box display="flex" alignItems="center">
    <Box display="flex" height="1px" backgroundColor="neutral.90" flexGrow={1} {...rest} />
    {typeof children === 'string' ? (
      <Text size="2" paddingX="8">
        {children}
      </Text>
    ) : (
      children
    )}
    <Box display="flex" height="1px" backgroundColor="neutral.90" flexGrow={1} {...rest} />
  </Box>
);

export default HorizontalRulerWithContent;
