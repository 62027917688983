import { ReactNode } from 'react';
import capitalize from 'lodash/capitalize';

import { Box, Link } from '@bitrise/bitkit';

const Placeholder = '%';
const emailDomainBlocked = `We don't accept registrations from this email domain.`;
const CommonErrorStrings: Record<string, string> = {
  blank: `${Placeholder} is required.`,
  invalid: `${Placeholder} is invalid.`,
  taken: `${Placeholder} is already in use.`,
  too_short: `${Placeholder} is too short.`,
  too_long: `${Placeholder} is too long.`,
  contains_plus: 'Alias emails are not allowed for this domain.',
  email_domain_blacklist: emailDomainBlocked,
  not_on_bitrise_io_domain: emailDomainBlocked,
  alias_exists: 'Alias already exists for this address.',
  password_contains_username: `${Placeholder} must not contain your email or username.`,
  password_contains_email: `${Placeholder} must not contain your email or username.`,
  value_mismatch: `${Placeholder} don't match.`,
  missing_number: `${Placeholder} is missing a number.`,
  missing_capital_letter: `${Placeholder} is missing a capital letter.`,
  not_available: `${Placeholder} is not available.`,
};

const getPrettyError = (errorKey: string, fieldName?: string): string => {
  if (fieldName === 'Email' && errorKey === 'taken') {
    return 'Email is already in use or invalid.';
  }
  const error = CommonErrorStrings[errorKey] || errorKey;
  return capitalize(String(error).replace(Placeholder, fieldName || ''));
};

const getDisplayableErrorMessage = (error: string, fieldName?: string): ReactNode => {
  const prettyError = getPrettyError(error, fieldName);
  const errorKey = prettyError?.toLowerCase();
  if (errorKey === 'compromised') {
    return (
      <span>
        The password you entered was <strong>previously exposed in data breaches</strong>. For more information, check
        the password at{' '}
        <Link href="https://haveibeenpwned.com/Passwords" isExternal isUnderlined>
          haveibeenpwned.com
        </Link>
        .
      </span>
    );
  }
  if (errorKey === 'too_many_attempts') {
    return 'Too many attempts. Please try again later.';
  }
  return prettyError;
};

const getDisplayableError = (error?: string | null, fieldName?: string): ReactNode => {
  if (!error) {
    return null;
  }
  return getDisplayableErrorMessage(error, fieldName);
};

export const getMultipleDisplayableErrors = (errors?: { error: string }[] | null, fieldName?: string): ReactNode => {
  if (!errors || errors.length === 0) {
    return null;
  }

  const errorMessages = errors.map(({ error }) => {
    const displayableErrorMessage = getDisplayableErrorMessage(error, fieldName);
    return <span key={error}>{displayableErrorMessage}</span>;
  });

  return (
    <Box as="span" display="flex" flexDirection="column">
      {errorMessages}
    </Box>
  );
};

export { CommonErrorStrings, getPrettyError, getDisplayableError };
