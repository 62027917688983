import { ReactNode } from 'react';
import { AxiosError } from 'axios';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

import {
  ErrorFieldNamesFor,
  ErrorHandler,
  ErrorItem,
  ErrorResponse,
  ErrorValueKeysFor,
  FlattenErrorFieldNamesToErrorList,
  FlattenErrorFieldNamesToFirstError,
  isComplexError,
  isSimpleError,
} from '@/models/Error';

const getFirstError = (errors: ErrorItem<any>[]): ErrorItem<any> | undefined => {
  return Array.isArray(errors) ? errors[0] : errors;
};

const parseFirstFieldErrors = <TErrorResponse extends ErrorResponse<any, any>>(
  errorResponse?: TErrorResponse | null,
): FlattenErrorFieldNamesToFirstError<TErrorResponse> => {
  if (errorResponse && isComplexError(errorResponse)) {
    const { errors } = errorResponse;
    return errors ? pickBy(mapValues(errors, getFirstError)) : Object();
  }

  return Object();
};

const parseAllFieldErrors = <TErrorResponse extends ErrorResponse<any, any>>(
  errorResponse?: TErrorResponse | null,
): FlattenErrorFieldNamesToErrorList<TErrorResponse> => {
  if (errorResponse && isComplexError(errorResponse)) {
    const { errors } = errorResponse;
    return errors ? mapValues(errors, (errs) => (Array.isArray(errs) ? [...errs] : undefined)) : Object();
  }
  return Object();
};

const getSimpleErrorMessage: ErrorHandler<ErrorResponse<any, any>> = <TErrorResponse extends ErrorResponse<any, any>>(
  error: AxiosError<TErrorResponse>,
): ReactNode => {
  return isSimpleError(error.response?.data)
    ? error.response?.data.message ||
        error.response?.data.error ||
        error.response?.data.errorMsg ||
        error.response?.data.errorMessage
    : null;
};

const getStandardErrorMessage: ErrorHandler<ErrorResponse<any, any>> = (
  error,
  defaultMessage = 'There was an unexpected error, please try again later!',
): ReactNode => {
  return getSimpleErrorMessage(error) || defaultMessage;
};

const hasComplexErrorForField = <TErrorResponse extends ErrorResponse<any, any>>(
  errorResponse: TErrorResponse,
  fieldName: ErrorFieldNamesFor<TErrorResponse>,
): boolean => {
  return (
    isComplexError(errorResponse) &&
    !!errorResponse.errors[fieldName] &&
    (errorResponse.errors[fieldName]?.length || 0) > 0
  );
};

const getComplexErrorsForField = <TErrorResponse extends ErrorResponse<any, any>>(
  errorResponse: TErrorResponse,
  fieldName: ErrorFieldNamesFor<TErrorResponse>,
): ErrorItem<ErrorValueKeysFor<TErrorResponse>>[] | null => {
  if (isComplexError(errorResponse) && hasComplexErrorForField(errorResponse, fieldName)) {
    return errorResponse.errors[fieldName] || null;
  }

  return null;
};

const getComplexErrorForFieldAtIndex = <TErrorResponse extends ErrorResponse<any, any>>(
  errorResponse: TErrorResponse,
  fieldName: ErrorFieldNamesFor<TErrorResponse>,
  index: number,
): ErrorItem<ErrorValueKeysFor<TErrorResponse>> | null => {
  if (index < 0) {
    return null;
  }

  const errors = getComplexErrorsForField(errorResponse, fieldName) || [];
  const { length } = errors;

  if (index >= length) {
    return null;
  }

  return errors[index];
};

export {
  parseFirstFieldErrors,
  hasComplexErrorForField,
  getComplexErrorsForField,
  getComplexErrorForFieldAtIndex,
  getStandardErrorMessage,
  parseAllFieldErrors,
};
