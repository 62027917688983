import { Box, BoxProps } from '@bitrise/bitkit';

export type PageContentProps = Omit<BoxProps, 'width'> & {
  width?: string;
};

const PageContent = ({ backgroundColor, width = '848px', ...rest }: PageContentProps): JSX.Element => {
  return (
    <Box backgroundColor={backgroundColor}>
      <Box
        display="flex"
        width={['100%', width]}
        overflow="hidden"
        flexDirection="column"
        flexGrow="1"
        gap="32"
        paddingX={['16', '32']}
        paddingY="48"
        marginX="auto"
        wordBreak="break-word"
        {...rest}
      />
    </Box>
  );
};

export default PageContent;
